const HeaderHOV = () => {
    return ( 
        <h1> Hero <span style={{color:"#999",}}> Or </span> <span style={{color: "red",}} > Villain</span> </h1>  

    );
}
         {/*         <h1 className="grey"> Hero Or <h1 style={{color: "red",}} > Villain</h1>Villain </h1>  */}
export default HeaderHOV;


