
const Navbar = () => {
    return (  
        <nav className="navbar">
            <div className="links">
                <a href="/demo" className="navlink" style= {{
                }}> demo </a>
                <a href="/start" className="navlink" style= {{
                }}> start </a>
                <a href="/app" className="navlink" style= {{
                }}> app </a>
                <a href="/info" className="navlink" style= {{
                }}> trow </a>
                <a href="/about" className="navlink" style= {{
                }}> external </a>

            </div>

        </nav>
    );
}
 
export default Navbar;