
//const [newPurpose, setNewPurpose] = useState("loading...");

const demo = () => {
    
    return ( 
        <div id="hello">
 
            <br />
{/*           <Button
            style={{ marginTop: 8 }}
            onClick={async () => {
              const result = tx(writeContracts.YourContract.buyHeroLevel(), update => { //make this a const just like newPurpose above  
                console.log("📡 buying level", update);
              });
            }}
            >
                Set Purpose!
            </Button>

            <button className="buttonspecial" id="risehero" > riseHero() </button>

            <button className="button" > getStatus() </button>

 */} 








            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 

             <br /> 
             <br /> 
             <br /> 
             <br /> 
             <br />  
             <br /> 

             <br /> 
        </div>
     );
}
 
export default demo;